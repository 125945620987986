import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { MdContactMail } from 'react-icons/md';

import { SubHeading } from '../../components';
import './Mentorship.css';

const Mentorship = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset form data to initial state immediately after form submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    
    // Send email via emailjs
    emailjs.send('service_6fbiw2k', 'template_68sbjwf', {
      from_name: formData.name,
      to_name: "Rakriss Property",
      from_email: formData.email,
      to_email: "osubor101@rakriss.com",
      message: formData.message,
    }, 'w2ZJYRvxeE8z5rLa2')
      .then((result) => {
        console.log(result.text);
        alert("Email sent successfully!");
      }, (error) => {
        console.log(error.text);
        alert("Failed to send the email. Please try again.");
      });
  };

  return (
    <div className="app__gallery flex__center" id="mentorship">
      <div className="app__gallery-content">
        
        <div className="content__left"> {/* Left side content */}
          <SubHeading title="Register your interest" />
          <h1 className="headtext__cormorant">Need Mentorship?</h1> <br />
          <MdContactMail color="#DCCA87" fontSize={27} />
          <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>
          Are you looking to gain expertise in serviced accommodation and achieve financial freedom? Would you benefit from personalised mentorship from an industry expert, putting you seven steps ahead? If so, reach out to us and we'll respond in just a few minutes.
          </p>
        </div>
        
        <div className="content__right"> {/* Right side form */}
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Name" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} required />
            <input type="email" placeholder="Email" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} required />
            <textarea placeholder="Message" value={formData.message} onChange={e => setFormData({ ...formData, message: e.target.value })} required></textarea>
            <button type="submit" className="custom__button">Submit</button>
          </form>
        </div>
        
      </div>
    </div>
  );
  
};

export default Mentorship;

