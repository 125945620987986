import React from 'react';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { MdWhatsapp } from 'react-icons/md';
import { BsHouseAddFill } from "react-icons/bs";

import { FooterOverlay, Newsletter } from '../../components';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="app__footer section__padding" id="subscribe">
      <FooterOverlay />
      <Newsletter />

      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">Contact Us</h1>
          <p className="p__opensans">9 East Parade, Whitley Bay, England, <br />NE26 1AP</p>
          <p className="p__opensans">+44 775-317-0868</p>
        </div>

        <div className="app__footer-links_logo">
          <BsHouseAddFill color="#DCCA87" fontSize={27} />
          <p className="p__opensans">&quot;Property investment: the pathway to financial freedom.&quot;</p>

          
          
          <div className="app__footer-links_icons">
            <a href="https://www.facebook.com/RKPSL/" target="_blank" rel="noopener noreferrer">
                <FiFacebook />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
                <MdWhatsapp />
            </a>
            <a href="https://instagram.com/rakrissproperty?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                <FiInstagram />
            </a>
          </div>  



        </div>

        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">Working Hours</h1>
          <p className="p__opensans">Monday-Friday:</p>
          <p className="p__opensans">08:00 am - 12:00 am</p>
          <p className="p__opensans">Saturday-Sunday:</p>
          <p className="p__opensans">07:00 am - 12:00 am</p>
        </div>
      </div>

      <div className="footer__copyright">
        <p className="p__opensans">©{currentYear} Rakriss Property. All Rights reserved.</p>
      </div>

    </div>
  );
}

export default Footer;
