const testimonial1 = [
  {
    title: 'Mila & Wumi',
    year: '2023',
    tags: 'Two months into the training, we made five-figure incomes.',
  },
  {
    title: 'Phoebe',
    year: '2023',
    tags: "The only result oriented training I've attended.",
  },
  {
    title: 'David',
    year: '2022',
    tags: "In all sincerity, this training is a hidden gem.",
  },
  {
    title: 'Olufemi',
    year: '2022',
    tags: 'I became financially free four months into this training.',
  },
  {
    title: 'Efe',
    year: '2022',
    tags: 'I thought the testimonials from past trainees were exaggerations, until I broke even one month into the training.',
  },
];

const testimonial2 = [
  {
    title: 'Rachael',
    year: '2021',
    tags: 'I thought I knew everything about property investment until I attended the training.',
  },
  {
    title: "Jack",
    year: '2021',
    tags: "After attending the training, I've never regretted resigning from my 9-5 job.",
  },
  {
    title: 'Nosa',
    year: '2021',
    tags: "The training eqipped me with all I needed to succeed in serviced accommodation.",
  },
  {
    title: 'Hannah',
    year: '2020',
    tags: 'Not just the training, but the mentorship catapulted me seven steps ahead.',
  },
  {
    title: 'Sammy',
    year: '2020',
    tags: "No matter what I write about the training, you can't understand it until you experience it.",
  },
];

const areas = [
  {
    title: 'Newcastle',
    subtitle: 'Our properties cater to contractors working in Newcastle.',
  },
  {
    title: 'Whitley Bay',
    subtitle: 'Our properties cater to contractors and tourists in Whitley Bay.',
  },
  {
    title: 'London',
    subtitle: 'Our properties are usually sought by contractors and locals.',
  },
  {
    title: 'Durham',
    subtitle: 'Our properties in this area have 90% occupancy yearly.',
  },
  {
    title: 'Bedlington',
    subtitle: 'Our properties in this area have 90% occupancy yearly.',
  },
  {
    title: 'Manchester',
    subtitle: 'Our properties in this area have 90% occupancy yearly.',
  },
];

export default { testimonial1, testimonial2, areas };