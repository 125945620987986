import React, { useState } from 'react';
import { images } from '../../constants';
import { MdTrendingUp } from 'react-icons/md';
import './AboutUs.css';

const AboutUs = () => {
  const [showMoreAbout, setShowMoreAbout] = useState(false);
  const [showMoreHistory, setShowMoreHistory] = useState(false);

  return (
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
      <div className="app__aboutus-content flex__center">
        <div className="app__aboutus-content_about">
          <h1 className="headtext__cormorant">About Us</h1>
          <MdTrendingUp color="#DCCA87" fontSize={27} />
          <p className="p__opensans">
            Our mission is to empower individuals with the knowledge and skills to achieve financial independence through the world of serviced accommodation.
            {showMoreAbout && <span> We are dedicated to fostering a community where members can exchange insights, share experiences, and collaborate on innovative solutions. We believe that with the right guidance and resources, anyone can unlock their potential and pave their path to financial success. By harnessing the opportunities in serviced accommodation, we aim to transform lives, ensuring a brighter and more prosperous future for all.</span>}
          </p>
          <button
            type="button"
            className="custom__button"
            onClick={() => setShowMoreAbout(!showMoreAbout)}
          >
            {showMoreAbout ? 'Show Less' : 'Know More'}
          </button>
        </div>

        <div className="app__aboutus-content_hotel flex__center">
          <img src={images.hotel} alt="about_image" />
        </div>

        <div className="app__aboutus-content_history">
          <h1 className="headtext__cormorant">Our Journey</h1>
          <MdTrendingUp color="#DCCA87" fontSize={27} />
          <p className="p__opensans">
          Over the past six years, our company has established a remarkable foothold in the industry. Our consistent track record of generating six-figure incomes is a testament to our expertise and dedication.
            {showMoreHistory && <span> We pride ourselves on not just achieving these milestones but passionately guiding others on how attainable such success can be. Our journey isn't merely about financial growth; it's about demonstrating possibilities, sharing our knowledge, and inspiring individuals to envision and achieve their own versions of success. We are here to show you the way.</span>}
          </p>
          <button
            type="button"
            className="custom__button"
            onClick={() => setShowMoreHistory(!showMoreHistory)}
          >
            {showMoreHistory ? 'Show Less' : 'Know More'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;