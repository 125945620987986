import React from 'react';

import { SubHeading } from '../../components';
import { images, data } from '../../constants';
import { BsHouseAddFill } from "react-icons/bs";
import './InvestmentAreas.css';

const AreaCard = ({ area: { title, subtitle } }) => (
  <div className="app__laurels_awards-card">
    <BsHouseAddFill color="#DCCA87" fontSize={27} />
    <div className="app__laurels_awards-card_content">
      <p className="p__cormorant" style={{ color: '#DCCA87' }}>{title}</p>
      <p className="p__opensans">{subtitle}</p>
    </div>
  </div>
);

const InvestmentAreas = () => (
  <div className="app__bg app__wrapper section__padding" id="areas">
    <div className="app__wrapper_info">
      <SubHeading title="Areas we currently invest in" />
      <h1 className="headtext__cormorant">Serviced Accommodation</h1>

      <div className="app__laurels_awards">
        {data.areas.map((area) => <AreaCard area={area} key={area.title} />)}
      </div>
    </div>

    <div className="app__wrapper_img2">
      <img src={images.laurels} alt="laurels_img" />
    </div>
  </div>
);

export default InvestmentAreas;