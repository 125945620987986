import React, { useState } from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleText = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="app__header app__wrapper section__padding" id="training">
      <div className="app__wrapper_info">
        <SubHeading title="Rakriss Property | Training & Mentorship" />
        <h1 className="app__header-h1">The Key To Financial Freedom</h1>
        <p className="p__opensans" style={{ margin: '2rem 0' }}>
        Welcome to Rakriss Property Training Services, your gateway to financial liberation through the lucrative world of serviced accommodation.
          {showMore && (
            <>
              <br />
              <br />
              <span>We specialise in equipping individuals with the knowledge, strategies, and insights needed to attain financial independence. Our comprehensive training programs empower you to leverage serviced accommodation properties as a key asset, opening doors to sustainable income streams and a secure financial future. <br /> <br/>Whether you're a novice or seasoned investor, our expert-led courses will guide you through the complexities of property management, investment strategies, and wealth-building techniques. <br/> <br/>Join us on this transformative journey towards financial freedom and unlock a world of opportunities in the serviced accommodation industry.</span>
            </>
          )}
        </p>
        <button type="button" className="custom__button" onClick={toggleText}>
          {showMore ? 'Show Less' : 'Explore More'}
        </button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.header} alt="header_img" />
      </div>
    </div>
  );
};

export default Header;