import React from 'react';
import { MdTrendingUp } from 'react-icons/md';


const SubHeading = ({ title }) => (
  <div style={{ marginBottom: '1rem' }}>
    <p className="p__cormorant">{title}</p>
    <MdTrendingUp color="#DCCA87" fontSize={27} />
  </div>
);

export default SubHeading;