import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Founder.css';

const Founder = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.founder} alt="founder_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Founder's word" />
      <h1 className="headtext__cormorant">Our Core Values</h1>

      <div className="app__founder-content">
        <div className="app__founder-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">At our company, we uphold the principles of Integrity, ensuring transparency in our training methods, and Excellence, striving for the pinnacle in serviced accommodation knowledge.</p>
        </div>
        <p className="p__opensans"> We deeply value Innovation, fostering fresh perspectives and strategies. Teamwork sits at our heart, promoting collaborative learning environments. We prioritize Customer-Centricity, adapting to evolving learner needs. </p>
      </div>

      <div className="app__founder-sign">
        <p>Christopher Osubor</p>
        <p className="p__opensans">Trainer & Founder</p>
      </div>
    </div>
  </div>
);

export default Founder;