import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './Testimonials.css';

const Testimonials = () => (
  <div className="app__testimonials flex__center section__padding" id="testimonials">
    <div className="app__testimonials-title">
      <SubHeading title="What our past trainees are saying about us" />
      <h1 className="headtext__cormorant">Testimonials</h1>
    </div>

    <div className="app__testimonials-menu">
      <div className="app__testimonials-menu_test1  flex__center">
        <div className="app__testimonials_menu_items">
          {data.testimonial1.map((test1, index) => (
            <MenuItem key={test1.title + index} title={test1.title} year={test1.year} tags={test1.tags} />
          ))}
        </div>
      </div>

      <div className="app__testimonials-menu_img">
        <img src={images.testimonials} alt="testimonial__img" />
      </div>

      <div className="app__testimonials-menu_test2  flex__center">
        <div className="app__testimonials_menu_items">
          {data.testimonial2.map((test2, index) => (
            <MenuItem key={test2.title + index} title={test2.title} year={test2.year} tags={test2.tags} />
          ))}
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>
      <a href="#mentorship"><button type="button" className="custom__button">Need Mentorship?</button></a>
    </div>
  </div>
);

export default Testimonials;