import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';

const Newsletter = () => {
  // const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [message, setMessage] = useState("");

  const handleSubscribe = (e) => {
    e.preventDefault();

    // Reset form data to initial state immediately after form submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    const name = "a New Subscriber"
    const imessage = "I am a new subscriber, kindly add me to your newsletter."
    emailjs.send('service_6fbiw2k', 'template_68sbjwf', {
      from_name: name,
      to_name: "Rakriss Property",
      from_email: formData.email,
      to_email: "osubor101@rakriss.com",
      message: imessage,
    }, 'w2ZJYRvxeE8z5rLa2')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage("Thank you for subscribing to our newsletter, you will hear from us in no time.");
      }, (error) => {
        console.log('FAILED...', error);
        setMessage("Oops, something went wrong. Please try again later.");
      });
  };

  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
       <SubHeading title="Newsletter" />
       <h1 className="headtext__cormorant">Subscribe To Our Newsletter</h1>
       <p className="p__opensans">And never miss latest Updates!</p>
      </div>
      <div className="app__newsletter-input flex__center">
        <input type="email" placeholder="Enter your email address" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
        <button type="button" className="custom__button" onClick={handleSubscribe}>Subscribe</button>
      </div>
      {message && <p style={{ color: '#fff' }}>{message}</p>}
    </div>
  );
};

export default Newsletter;