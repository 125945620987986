import bg from '../assets/bg.png';
import founder from '../assets/founder.png';
import hotel from '../assets/hotel.png';
import testimonials from '../assets/testimonials.png';
import overlaybg from '../assets/overlaybg.png';
import header from '../assets/header.jpg';
import laurels from '../assets/laurels.png';
import quote from '../assets/quote.png';
import rakriss from '../assets/rakriss.svg';

export default {
  bg,
  founder,
  hotel,
  testimonials,
  overlaybg,
  header,
  laurels,
  quote,
  rakriss,
};